<template>
    <div class="upDetail">
        <div class="title">升级套餐包指引</div>
        <div class="note">（生效时间：立即生效，下一周期生效）</div>
        <div class="price">
            <span>价格计算方式：</span>
            <div>新套餐单价×周期数-｛旧套餐单价x(旧套餐周期数-1)}=需补差价</div>
        </div>
        <div class="first">
            <span>* 升级前</span>
            <div class="info-img" :style="{backgroundImage: 'url(' + require('@/assets/img/1.jpg' ) + ')'}"></div>
            <div class="info-img" :style="{backgroundImage: 'url(' + require('@/assets/img/2.jpg' ) + ')'}"></div>
        </div>
        <div class="first">
            <span>* 升级后，立即生效</span>
            <div class="info-img" :style="{backgroundImage: 'url(' + require('@/assets/img/3.jpg' ) + ')'}"></div>
        </div>
        <div>
            <van-button color="#EB5E00" size="large" @click="submit">确认</van-button>
        </div>
    </div>
</template>

<script>
export default {
    name:'upDetail',
    data() {
        return {
            
        }
    },
    methods: {
        submit(){
            this.$router.push({name:'upPack'})
        }
    },
    created(){
        this.$store.commit('setPageTitle', "升级套餐引导");
    }
}
</script>

<style lang="scss" scoped>
.upDetail{
    text-align: left;padding:0px 50px 30px 50px;color: #333;
    .title{
        font-size: 45px;font-weight: 600;
    }
    .note{
        font-size: 24px;
    }
    .price{
        div{
            font-size: 22px;
        }
        span:first-child{
            font-size: 30px;color: #1F9DF1;font-weight: 600;
        }
    }
    .first{
        span{
            font-size: 30px;color: #1F9DF1;font-weight: 600;
        }
        
    }
    .info-img{
        height: 260px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        // background-size: cover;
        background-position: center;
        margin-top: 20px;
        
    }
}
    
</style>
